<template>
  <div class="platform-index">
    <LoadingComponent v-if="!isMounted || !isImagesLoadedMainSwiper" />

    <main-swiper-component
      :status="status"
      :passEventToParentComponent="displayTsunaguOnlineProductHtmlWrapperFromSwiper"
      :isDisplayConnectix="isDisplayConnectix"
      @isImagesLoadedMainSwiper="changeIsImagesLoadedMainSwiper()"
    />

    <!-- モーダル表示に関係する領域 -->
    <template v-if="showModal">
      <ModalDisplayTsunaguOnlineProduct
        :srcHtml="mySrcHtmlFromSwiper"
        :handle-show-modal="setShowModal"
        :passProductUrl="handlePassedProductUrl"
        ref="modalDisplayTsunaguOnlineProduct"
      />
    </template>

    <div class="contents">
      <!-- お住まい登録済みの場合に表示する要素 -->
      <PortalServiceController @updateLeftMenuLogoFromPortalServiceController="sendUpdateLeftMenuLogoAtPlatform()" />
      <!-- GW光連携済みの場合に表示する要素 -->
      <PortalServiceGameWith v-if="isLinkedGameWithFlag" />
      <!-- よくある質問: 物件登録なしの場合 -->
      <div class="blc">
        <template v-if="serviceType">
          <!-- サブスク商品 Portas会員ステータスが退会申し込みのとき表示しない -->
          <template v-if="displayPortasSubscriptionPurchaseLead">
            <SubscriptionCommonComponent />
          </template>

          <!-- つなぐオンラインショップ  -->
          <template v-if="displayTsunaguOnlineShop() && isPreviewTnshop === true">
            <div class="blc">
              <TsunaguOnlineShop @updateLeftMenuLogoFromTsunaguOnlineShop="sendUpdateLeftMenuLogoAtPlatform()" ref="tsunaguOnlineShop" />
            </div>
          </template>

          <!-- Mcloudを連携している場合 -->
          <template v-if="isLinkedToMcloud && isPreviewMcloud">
            <div class="blc">
              <McloudCommonComponent />
            </div>
          </template>

          <!-- Mcloudを連携していないかつportas退会申し込み中でないかつGW光連携していない場合 -->
          <template v-if="!isLinkedToMcloud && isDisplayNotLinkedMcloud && !isLinkedGameWithFlag">
            <div class="blc">
              <McloudCommonComponent :isDisplayNotLinkedMcloud="isDisplayNotLinkedMcloud" />
            </div>
          </template>
          <div class="blc">
            <FrequentlyAskedQuestions :serviceType="serviceType" />
          </div>
        </template>
        <div class="blc">
          <TopPageAnnouncements :status="status" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* campain-faq
---------------------------------------------------- */
.campain-faq {
  padding: 0 40px;
  margin: 0 auto;
  max-width: 1220px;
}

/* campain-h2
---------------------------------------------------- */
.campain-h2 {
  position: relative;

  &__title {
    display: inline-block;
    margin: 0;
    font-size: 24px;
    line-height: 1.6;
    border-bottom: 3px solid #0097e0;
  }
}

.float-div {
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}

.img-sp {
  display: none;
}

@media only screen and (max-width: 767px) {
  .campain-faq {
    padding: 20px 0;
    width: 94%;
    margin: 0 auto;
  }

  .img-pc {
    display: none;
    width: 100%;
  }

  .img-sp {
    display: block;
    width: 100%;
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import MainSwiperComponent from '../components/main-swiper.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { MEMBER_STATUS } from '@/shared/const/member-status';
import FrequentlyAskedQuestions from '../components/frequently-asked-questions.vue';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { UcomCustomerResponse } from '@/shared/classes/external-api/ucom/customer-response';
import { ISP_MEMBER_STATUS, UA_TYPE } from '@/shared/const/service-type';
import { Member } from '@/shared/classes/spf-api/member';
import { Property } from '@/shared/classes/spf-api/property';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { FiveACustomer } from '@/shared/classes/external-api/five-a/customer-response';
import { getIspMemberStatusEMansion, getIspMemberStatusFiveA, getIspMemberStatusUcom } from '@/shared/util/func-get-isp-member-status';
import PortalServiceController from './components/portal-service-controller.vue';
import PortalServiceGameWith from './components/portal-service-gamewith.vue';
import { FiveAProperty } from '@/shared/classes/external-api/five-a/property-response';
import { UcomPropertyResponse } from '@/shared/classes/external-api/ucom/property-response';
import { isDisplayConnectixInPortalCaseEMansion, isDisplayConnectixInPortalCaseFiveA, isDisplayConnectixInPortalCaseUcom } from '@/shared/util/func-is-display-connectix-in-portal';
import McloudCommonComponent from '../components/mcloud-common.vue';
import TsunaguOnlineShop from '@/shared/components/tnos/tsunagu-online-shop.vue';
import { TsunaguOnlineProductHtmlDisplay, TsunaguOnlineProductHtmlDisplayService } from '@/shared/services/tnos/tsunagu-online-product-html-display-service';
import ModalDisplayTsunaguOnlineProduct from '@/shared/components/tnos/modal-display-tsunagu-online-product.vue';
import SubscriptionCommonComponent from './components/subscription-common.vue';
import { SpfApiExternalGamewithAccessor } from '@/infra/accessor/spf/gamewith/spf-api-external-gamewith-accessor';
import TopPageAnnouncements from '../components/top-page-announcements.vue';

export default defineComponent({
  name: 'platform-index',
  components: {
    MainSwiperComponent,
    LoadingComponent,
    FrequentlyAskedQuestions,
    PortalServiceController,
    PortalServiceGameWith,
    McloudCommonComponent,
    TsunaguOnlineShop,
    TopPageAnnouncements,
    ModalDisplayTsunaguOnlineProduct,
    SubscriptionCommonComponent,
  },
  data: () => ({
    status: 'members-only-top',
    isMounted: false,
    isImagesLoadedMainSwiper: false, // ファーストビューで表示されるmain-swiperの画像の読み込みがすべて終わったかどうか
    // よくある質問の表示を切り替えるために使う
    serviceType: '',
    // connectix表示可否 swiper
    isDisplayConnectix: false,
    // 会員情報
    member: null as Member | null,
    // 会員ステータス
    memberStatus: null as MemberStatus | null,
    // 物件情報
    property: null as Property | null,
    // ISP(e-mansion)会員ステータス
    ispMemberStatusEMansion: '',
    // ISP(FiveA)会員ステータス
    ispMemberStatusFiveA: '',
    // ISP(ucom)会員ステータス
    ispMemberStatusUcom: '',
    // Mcloudと連携しているかどうか
    isLinkedToMcloud: false,
    // Mcloud未連携時にリンクを表示するかどうか
    isDisplayNotLinkedMcloud: true,
    // Mcloudへのリンクを表示しない時に使う
    isPreviewMcloud: true,
    // swiperでクリックされた商品のスクレイピング処理済みHTML
    mySrcHtmlFromSwiper: '',
    showModal: false,
    // つなぐオンラインコンテンツを表示しない時に使う
    isPreviewTnshop: true,
    /** Portasサブスク商材の購入導線を表示するかどうか */
    displayPortasSubscriptionPurchaseLead: true,
    //GW光と連携しているかどうか
    isLinkedGameWithFlag: false,
  }),
  async created() {
    // 会員情報取得
    this.member = this.$store.getters['memberStore/member'];
    this.memberStatus = this.$store.getters['memberStore/memberStatus'];

    if (this.member?.propertyId) {
      this.property = this.$store.getters['propertyStore/property'];
    }

    if (this.memberStatus?.status === MEMBER_STATUS.IN_CANCEL_APPLICATION) {
      this.displayPortasSubscriptionPurchaseLead = false;
    }

    // Mcloud連携の判定
    if (this.member?.primaryKeyMcloud) {
      this.isLinkedToMcloud = true;
    } else if (this.memberStatus?.status === MEMBER_STATUS.IN_CANCEL_APPLICATION) {
      // Portas退会申し込み中 の場合Mcloud登録リンクは表示しない
      this.isDisplayNotLinkedMcloud = false;
    }

    // e-mansion のとき
    if (this.property?.uaType === UA_TYPE.E_MANSION) {
      if (this.property.apartmentId) {
        this.setFlagProvidedConnectixCaseEMansion();
        if (this.member?.primaryKeyMye) {
          this.processEMansionCustomerApiResponse();
        }
      }

      // ISP会員ステータスが退会済みログイン不可となるとき
      this.setDisplayConnectixWhenIspWithdrawalCaseEmansion();

      // Portas会員ステータス: 7 かつ primaryKeyMyeがある 場合 mcloudリンク表示重複を防ぐための処理
      // Portas会員ステータス: 7 かつ primaryKeyMyeがある 場合 つなぐコンテンツ表示重複を防ぐための処理
      if (this.memberStatus?.status === MEMBER_STATUS.IN_CANCEL_APPLICATION && this.member?.primaryKeyMye) {
        if (this.isLinkedToMcloud) {
          this.isPreviewMcloud = false;
        }
        if (this.member?.primaryKeyTnshop) {
          this.isPreviewTnshop = false;
        }
      }

      // ucom のとき
    } else if (this.property?.uaType === UA_TYPE.UCOM) {
      if (this.property.buildingId) {
        this.setFlagProvidedConnectixCaseUcom();
      }

      if (this.member?.primaryKeyUcom) {
        this.processUcomCustomerApiResponse();

        // Portas会員ステータス: 7 かつ primaryKeyUcomがある 場合 mcloudリンク表示重複を防ぐための処理
        if (this.memberStatus?.status === MEMBER_STATUS.IN_CANCEL_APPLICATION && this.member?.primaryKeyUcom) {
          if (this.isLinkedToMcloud) {
            this.isPreviewMcloud = false;
          }
          if (this.member?.primaryKeyTnshop) {
            this.isPreviewTnshop = false;
          }
        }
      }

      // ISP会員ステータスが退会済みログイン不可となるとき
      this.setDisplayConnectixWhenIspWithdrawalCaseUcom();

      // Five.A のとき
    } else if (this.property?.uaType === UA_TYPE.FIVE_A) {
      if (this.property.apartmentId) {
        this.setFlagProvidedConnectixCaseFiveA();
        if (this.member?.primaryKeyMye) {
          this.processFiveACustomerApiResponse();
        }
      }

      // ISP会員ステータスが退会済みログイン不可となるとき
      this.setDisplayConnectixWhenIspWithdrawalCaseFiveA();

      // Portas会員ステータス: 7 かつ primaryKeyMyeがある 場合 mcloudリンク表示重複を防ぐための処理
      if (this.memberStatus?.status === MEMBER_STATUS.IN_CANCEL_APPLICATION && this.member?.primaryKeyMye) {
        if (this.isLinkedToMcloud) {
          this.isPreviewMcloud = false;
        }
        if (this.member?.primaryKeyTnshop) {
          this.isPreviewTnshop = false;
        }
      }
    }
    //GW光と連携しているか
    this.isLinkedGameWithFlag = await this.isLinkedGameWith();

    this.serviceType = this.getServiceType();
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    changeIsImagesLoadedMainSwiper() {
      this.isImagesLoadedMainSwiper = true;
    },
    /**
     * e-mansionのとき、Connectix提供物件かどうかを取得する
     */
    setFlagProvidedConnectixCaseEMansion(): void {
      const eMansionProperty: EMansionProperty | null = this.$store.getters['eMansionCommonStore/property'];

      if (eMansionProperty instanceof EMansionProperty) {
        // Connectix提供物件かどうか
        if (eMansionProperty.op.sdwan.flag === '1') {
          this.isDisplayConnectix = true;
        }
      }
    },
    /**
     * e-mansionのとき、契約基本情報を使う処理
     */
    processEMansionCustomerApiResponse(): void {
      // e-mansionの契約基本情報をStoreから取得
      const eMansionCustomer: EMansionCustomer | null = this.$store.getters['eMansionCommonStore/customer'];

      if (eMansionCustomer instanceof EMansionCustomer) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.ispMemberStatusEMansion = getIspMemberStatusEMansion(this.memberStatus!, eMansionCustomer);
        const isProvidedConnectix = this.isDisplayConnectix;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.isDisplayConnectix = isDisplayConnectixInPortalCaseEMansion(this.memberStatus!, this.ispMemberStatusEMansion, isProvidedConnectix);
      }
    },
    /**
     * ISP会員ステータスが退会済みログイン不可となるとき
     */
    setDisplayConnectixWhenIspWithdrawalCaseEmansion(): void {
      if (this.memberStatus?.status === MEMBER_STATUS.ACCOUNT_REGISTERED_NO_PROPERTY) {
        if (!this.member?.primaryKeyMye) {
          this.isDisplayConnectix = false;
        }
      }

      if (this.memberStatus?.status === MEMBER_STATUS.IN_CANCEL_APPLICATION) {
        if (!this.member?.primaryKeyMye) {
          this.isDisplayConnectix = false;
        }
      }
    },
    /**
     * ucomのとき、Connectix提供物件かどうかを取得する
     */
    setFlagProvidedConnectixCaseUcom(): void {
      const ucomProperty: UcomPropertyResponse | null = this.$store.getters['ucomCommonStore/property'];

      if (ucomProperty instanceof UcomPropertyResponse) {
        // Connectix提供物件かどうか (UCOM物件基本情報APIのレスポンス.connectix_start_date に日付が存在するとき、Connectix提供物件と判定する)
        if (ucomProperty.connectix_start_date) {
          this.isDisplayConnectix = true;
        }
      }
    },
    /**
     * ucomのとき、契約基本情報を使う処理
     */
    processUcomCustomerApiResponse(): void {
      const ucomCustomer: UcomCustomerResponse | null = this.$store.getters['ucomCommonStore/customer'];

      if (ucomCustomer instanceof UcomCustomerResponse) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.ispMemberStatusUcom = getIspMemberStatusUcom(this.memberStatus!, ucomCustomer);
        const isProvidedConnectix = this.isDisplayConnectix;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.isDisplayConnectix = isDisplayConnectixInPortalCaseUcom(this.memberStatus!, this.ispMemberStatusUcom, isProvidedConnectix);
      }
    },
    /**
     * ISP会員ステータスが退会済みログイン不可となるとき
     */
    setDisplayConnectixWhenIspWithdrawalCaseUcom(): void {
      if (this.memberStatus?.status === MEMBER_STATUS.ACCOUNT_REGISTERED_NO_PROPERTY) {
        if (!this.member?.primaryKeyUcom) {
          this.isDisplayConnectix = false;
        }
      }

      if (this.memberStatus?.status === MEMBER_STATUS.IN_CANCEL_APPLICATION) {
        if (!this.member?.primaryKeyUcom) {
          this.isDisplayConnectix = false;
        }
      }
    },
    /**
     * Five.Aのとき、Connectix提供物件かどうかを取得する
     */
    setFlagProvidedConnectixCaseFiveA(): void {
      const fiveAProperty: FiveAProperty | null = this.$store.getters['fiveACommonStore/property'];

      if (fiveAProperty instanceof FiveAProperty) {
        // Connectix提供物件かどうか
        if (fiveAProperty.op.sdwan.flag === '1') {
          this.isDisplayConnectix = true;
        }
      }
    },
    /**
     * Five.Aのとき、契約基本情報を使う処理
     */
    processFiveACustomerApiResponse(): void {
      // Five.Aの契約基本情報をStoreから取得
      const fiveACustomer: FiveACustomer | null = this.$store.getters['fiveACommonStore/customer'];

      if (fiveACustomer instanceof FiveACustomer) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.ispMemberStatusFiveA = getIspMemberStatusFiveA(this.memberStatus!, fiveACustomer);
        const isProvidedConnectix = this.isDisplayConnectix;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.isDisplayConnectix = isDisplayConnectixInPortalCaseFiveA(this.memberStatus!, this.ispMemberStatusFiveA, isProvidedConnectix);
      }
    },
    /**
     * ISP会員ステータスが退会済みログイン不可となるとき
     */
    setDisplayConnectixWhenIspWithdrawalCaseFiveA(): void {
      if (this.memberStatus?.status === MEMBER_STATUS.ACCOUNT_REGISTERED_NO_PROPERTY) {
        if (!this.member?.primaryKeyMye) {
          this.isDisplayConnectix = false;
        }
      }

      if (this.memberStatus?.status === MEMBER_STATUS.IN_CANCEL_APPLICATION) {
        if (!this.member?.primaryKeyMye) {
          this.isDisplayConnectix = false;
        }
      }
    },
    /**
     * Portas会員退会申し込み中 (会員ステータスが7) のときも
     * よくある質問が表示されるように追加
     */
    getServiceType() {
      // ucom物件 かつ Portas会員ステータス: 7 かつ ISP会員ステータス: 解約申し込み中
      // Connectix用質問表示制御があるので、 portal-service-ucom コンポーネントで質問表示
      if (this.property?.uaType === UA_TYPE.UCOM) {
        if (this.memberStatus?.status === MEMBER_STATUS.IN_CANCEL_APPLICATION && this.ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.IN_CANCEL_APPLICATION) {
          return '';
        }
      }

      if (this.memberStatus?.status === MEMBER_STATUS.ACCOUNT_REGISTERED_NO_PROPERTY || this.memberStatus?.status === MEMBER_STATUS.IN_CANCEL_APPLICATION) {
        return 'url-platform-no-property';
      }

      return '';
    },
    displayTsunaguOnlineShop(): boolean {
      if (this.memberStatus?.status === MEMBER_STATUS.IN_CANCEL_APPLICATION && !this.member?.primaryKeyTnshop) {
        return false;
      }
      return true;
    },
    /**
     * swiperの詳しく見るボタンが押下されたときのイベントを処理する
     */
    async displayTsunaguOnlineProductHtmlWrapperFromSwiper(e: Event): Promise<void> {
      const tsunaguOnlineProductHtmlDisplay = await TsunaguOnlineProductHtmlDisplayService.makeWrapper(e);
      this.displayTsunaguOnlineProductHtml(tsunaguOnlineProductHtmlDisplay);
    },
    /**
     * 1. スマホのとき: スマホのときスクレイピング表示をする用のページへ遷移する
     * 2. PCのとき: スクレイピング処理をしたHTMLを表示する
     */
    displayTsunaguOnlineProductHtml(tsunaguOnlineProductHtmlDisplay: TsunaguOnlineProductHtmlDisplay | undefined): void {
      if (tsunaguOnlineProductHtmlDisplay) {
        if (tsunaguOnlineProductHtmlDisplay.isUserAgentMobile) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          window.open(tsunaguOnlineProductHtmlDisplay.spUrl!, '_blank');
        } else {
          this.showModal = true;
          this.mySrcHtmlFromSwiper = tsunaguOnlineProductHtmlDisplay.srcHtml ? tsunaguOnlineProductHtmlDisplay.srcHtml : '';

          // モーダル表示するコンポーネントのdom操作できるようになったら、モーダル表示ONにする
          this.$nextTick(() => {
            const modalDisplayTsunaguOnlineProduct = this.$refs.modalDisplayTsunaguOnlineProduct as InstanceType<typeof ModalDisplayTsunaguOnlineProduct>;
            if (modalDisplayTsunaguOnlineProduct) {
              modalDisplayTsunaguOnlineProduct.openModal();
            }
          });
        }
      }
    },
    /**
     * モーダルの表示・非表示を制御する
     */
    setShowModal(value: boolean) {
      this.showModal = value;
    },
    /**
     * スクレイピング処理後に表示しているページ内にて、
     * 別商品へのリンクが押下されたとき、リンクが押下された商品が表示されるようURLを適切なメソッドに渡す
     */
    async handlePassedProductUrl(url?: string): Promise<void> {
      if (url) {
        const tsunaguOnlineProductHtmlDisplay = await TsunaguOnlineProductHtmlDisplayService.make(url);
        this.displayTsunaguOnlineProductHtml(tsunaguOnlineProductHtmlDisplay);
      }
    },
    /**
     * つなぐネットオンラインショップの 「このページを更新する」 が押下されたとき、
     * 左メニューにロゴが表示されるようにする(下の階層のコンポーネントから伝播されてくる)
     */
    sendUpdateLeftMenuLogoAtPlatform(): void {
      this.$emit('updateLeftMenuLogoFromPlatform', true);
    },
    async isLinkedGameWith(): Promise<boolean> {
      if (!this.member?.primaryKeyGW) {
        return false;
      }
      if (!this.memberStatus?.encryptedPrimaryKeyGw) {
        return false;
      }
      return true;
    },
  },
});
</script>
