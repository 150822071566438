<template>
  <div class="login-and-register-guide-at-top">
    <div
      :class="{
        'my-guide-wrapper': status === 'top',
        '': status === 'members-only-top',
      }"
    >
      <div class="my-section-outer">
        <section class="my-section">
          <div class="c-content">
            <div class="c-content__item display-flex">
              <div class="description">
                <div class="Portasection">
                  <h2>【重要なお知らせ】PortasウェブサイトにおけるTLS1.0/1.1のサポート終了について</h2>
                </div>
                <div class="description">
                  <p class="font-weight-700">平素は、当社サービスをご利用いただきまして、誠にありがとうございます。</p>
                  <br />
                  <p class="red font-weight-700">
                    当社ではPortasウェブサイトのセキュリティを強化するため、2025年1月8日にインターネット通信暗号化方式「TLS1.0」および「TLS1.1」のサポートを終了いたします。
                    これによって「TLS1.2」や「TLS1.3」に対応していない一部の端末や古いブラウザからは、サイトの閲覧ができなくなります。
                  </p>
                  <br />
                  <p class="font-weight-700">ご迷惑をお掛けいたしますが、ご理解、ご協力の程、よろしくお願い申しあげます。</p>
                </div>
              </div>
            </div>
            <div class="c-content__item">
              <div class="description">
                <p class="font-weight-700">お客様への影響について</p>
              </div>
              <ul class="c-list">
                <li class="c-list__item red font-weight-700">
                  <h4 style="margin: 0">最新版のブラウザをご利用の場合は、特に影響はございません。</h4>
                </li>
                <li class="c-list__item">
                  <h4 style="margin: 0">古いバージョンのブラウザをご利用の場合は、最新版にアップデートをお願いします。</h4>
                </li>
              </ul>
              <div class="description">
                <ul class="c-list display-flex">
                  <li class="c-list__item c-link-list__item">
                    <a
                      class="link"
                      href="https://support.microsoft.com/ja-jp/topic/microsoft-edge-%E3%81%AE%E6%9B%B4%E6%96%B0%E8%A8%AD%E5%AE%9A-af8aaca2-1b69-4870-94fe-18822dbb7ef1"
                      target="_blank"
                      rel="noopener noreferrer"
                      >Microsoft Edge [↗]</a
                    >
                  </li>
                  <li class="c-list__item c-link-list__item">
                    <a class="link" href="https://support.google.com/chrome/answer/95414?hl=ja&co=GENIE.Platform%3DDesktop" target="_blank" rel="noopener noreferrer"
                      >Google Chrome [↗]</a
                    >
                  </li>
                  <li class="c-list__item c-link-list__item">
                    <a class="link" href="https://support.mozilla.org/ja/kb/update-firefox-latest-release" target="_blank" rel="noopener noreferrer">Mozilla Firefox [↗]</a>
                  </li>
                  <li class="c-list__item c-link-list__item">
                    <a class="link" href="https://support.apple.com/ja-jp/102665" target="_blank" rel="noopener noreferrer">Safari [↗]</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
div.my-guide-wrapper {
  padding: 0 16px;
}

div.my-section-outer {
  margin-top: 16px;
}

div.my-logo {
  width: 100%;
  padding-top: 16px;
  & img {
    width: 160px;
  }
}

.login-u {
  color: #cf1225;
}

.login-url-sp {
  margin-bottom: 10px;
}

button.topbtn {
  appearance: none;
  border: 0;
  border-radius: 2px;
  color: #ffffff;
  padding: 12px 25px;
  line-height: 24px;
  font-size: 15px;
  min-width: 250px;
  margin: 0 10px;
  top: 29px;
  box-sizing: border-box;
  position: relative;
}

.Portasection {
  margin-bottom: 10px;
}

.PortasQAsection {
  padding-left: 16px;
}

.c-link-list__item {
  letter-spacing: 0.8px;
  &:before {
    font-family: 'Material Icons';
    content: '\e409';
    position: relative;
    top: 3px;
    font-size: 16px;
  }
  & a {
    text-decoration: underline;
  }
}

.my-section {
  padding: 32px 16px;
  background-color: #efefef;
  & h2 {
    margin: 0;
    font-size: 20px;
  }
}

.c-content {
  margin-top: 0;
  &__item {
    padding-bottom: 32px;
    margin-bottom: 16px;
    border-bottom: 1px solid #a2a2a2;
    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }
    & div.description {
      text-align: left;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      & p {
        &.font-weight-700 {
          font-weight: 700;
        }
      }
    }
    & div.my-button {
      margin-top: 24px;
      text-align: center;
    }
  }
}

.c-list {
  margin: 0;
  padding: 0;
  padding-left: 32px;
  &.display-flex {
    display: flex;
    list-style-type: none;
    padding-left: 16px;
    & .c-list__item {
      padding-right: 40px;
      &:last-child {
        padding-right: 0;
      }
    }
  }
  &__item {
    padding: 0;
  }
}

.responsive-link {
  display: none;
}

@media (max-width: 450px) {
  .my-logo {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .responsive-link {
    display: block;
    justify-content: flex-start;
    color: #cf1225;
  }
}

@media only screen and (min-width: 1000px) {
  div.my-guide-wrapper {
    max-width: 992px;
    margin: 0 auto;
  }
  .c-content {
    margin-top: 0;
    &__item {
      &.display-flex {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin & div.description {
          margin: 0;
        }
        & div.my-button {
          margin: 0;
          padding-left: 0px;
          padding-right: 10px;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  button.topbtn {
    appearance: none;
    border: 0;
    border-radius: 2px;
    color: #ffffff;
    padding: 12px 25px;
    line-height: 24px;
    font-size: 15px;
    width: 100%;
    margin: 0;
    top: 2px;
    box-sizing: border-box;
    position: relative;
  }
}

@media (max-width: 525px) {
  ul {
    display: flex !important;
    flex-direction: column !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 999px) {
  button.topbtn {
    appearance: none;
    border: 0;
    border-radius: 2px;
    color: #ffffff;
    padding: 12px 25px;
    line-height: 24px;
    font-size: 15px;
    min-width: 250px;
    margin: 0;
    top: 2px;
    box-sizing: border-box;
    position: relative;
  }
}

@media only screen and (min-width: 1200px) {
  div.my-section-outer {
    margin-top: 32px;
  }
  div.my-logo {
    padding-top: 40px;
    & img {
      width: 200px;
    }
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'top-announcement',
  props: {
    status: {
      type: String,
    },
  },
});
</script>
