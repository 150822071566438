<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>メールアドレス登録／変更</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>
          <router-link to="/ucom">UCOM光レジデンス会員専用トップ</router-link>
        </li>
        <li>メールアドレス登録／変更</li>
      </ul>
      <!-- /breadcrumb -->

      <!-- application-flow -->
      <ul class="application-flow grid pc-grid3 sp-grid3 gap10">
        <li>入力</li>
        <li>確認</li>
        <li class="stay">完了</li>
      </ul>
      <!-- /application-flow -->

      <!-- blc:メールアドレス登録／変更 -->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../../images/service-h2.svg" alt="メールアドレス登録／変更" />メールアドレス登録／変更&emsp;完了</h2>
        <div class="text-box">
          <p>お申し込みいただきました内容につきまして手続きが完了しました。</p>
          <p>メールソフトの設定情報は<a :href="`${ucomRUrl}/option/mail/`" class="url" target="UCOM">「こちら」</a>をご参照ください。</p>
        </div>
        <h3 class="service-h3">お申し込み内容</h3>
        <table class="table-type2">
          <tr>
            <th class="va-middle" style="vertical-align: top; font-size: 13px">アカウントID</th>
            <td style="vertical-align: middle; overflow-x: auto; max-width: 920px">
              <span class="font13px" style="max-width: 900px">{{ displayData.accountName }}</span>
            </td>
          </tr>
          <tr>
            <th class="va-middle" style="vertical-align: top; font-size: 13px">利用メール</th>
            <td style="vertical-align: middle; overflow-x: auto; max-width: 920px">
              <span class="font13px" style="max-width: 900px">{{ displayData.displayMailAddressType }}</span>
            </td>
          </tr>
          <tr>
            <th class="va-middle" style="vertical-align: middle; font-size: 13px">メールアドレス</th>
            <td style="vertical-align: middle; overflow-x: auto; max-width: 920px">
              <span class="font13px" style="max-width: 900px">{{ displayData.mailAddress }}</span>
            </td>
          </tr>
          <tr>
            <th class="va-middle" style="vertical-align: middle; font-size: 13px">お申し込み日</th>
            <td style="vertical-align: middle; overflow-x: auto; max-width: 920px">
              <span class="font13px" style="max-width: 900px">{{ displayData.registerSubscriptionOn }}</span>
            </td>
          </tr>
          <tr v-if="displayData.mailAddressType === mailAddressType.UCOM">
            <th class="va-middle" style="vertical-align: middle; font-size: 13px">注意事項</th>
            <td style="vertical-align: middle; overflow-x: auto; max-width: 920px">
              <p class="caution-title"><b>■サービスについて</b></p>
              <p>UCOM光の提供するメールアドレスでは、1GBのメールボックスをご利用いただくことができ<br />有料で10GBに容量を増設することができます。</p>
              <p>また、Webスペース/メール転送サービス/ウイルスチェックサービスをご利用いただくことができます。</p>
              <ul>
                <li>お申し込み後のキャンセルは行えません。</li>
                <li>お申し込み後、すぐにご利用いただけます。</li>
                <li>利用料金は購入翌月から発生いたします。</li>
                <li>
                  メールアドレスはアカウントIDに付随し、単体での解約手続きは行えません。<br />
                  アカウントIDを残したままメールアドレスを解約されたい場合は、<br />
                  「他社のメールアドレスを利用する」設定にご変更ください。
                </li>
                <li>メールアドレスの変更に費用は発生しませんが、同月内に、取得・解約・取得を繰り返された場合でも<br />メールサービス購入毎の費用が発生いたします。</li>
                <li>当月末日までにマイページにて解約されたものに対し、当月での解約を行います。</li>
                <li>解約時は、解約月の当月分の料金が発生いたします。</li>
                <li>利用開始および解約時の日割り計算は行いません。</li>
              </ul>
              <p><font color="blue">▶</font><a :href="`${ucomUrl}`" class="url" target="UCOM">メールアドレス変更時の注意事項</a></p>
            </td>
          </tr>
          <tr v-if="displayData.mailAddressType === mailAddressType.OTHER">
            <th class="va-middle" style="vertical-align: middle; font-size: 13px">注意事項</th>
            <td style="vertical-align: middle; overflow-x: auto; max-width: 920px">
              <p class="caution-title"><b>■サービスについて</b></p>
              <p>
                セキュリティオプション等、アカウントIDへのメールアドレスの設定が必要で、<br />
                UCOM光のメールアドレスが不要なお客様については、既にご利用頂いているフリーメールや携帯電話のメールアドレス、他社のメールアドレスを設定いただくことができます。
              </p>
              <p>受信の文字数に制限があるアドレスの場合、必要情報が確認できない場合がありますのでご注意ください。</p>
              <p class="margin-vertical-12"><b>■メールアドレス変更時の注意事項</b></p>
              <ul>
                <li>UCOM光メールアドレスから他社のメールアドレスへ変更された場合、今まで登録されていたメール、Webスペースのご利用ができなくなります。</li>
                <li>その他注意事項は、「UCOM光の提供するメールアドレスを取得する」の「■メールアドレス変更時の注意事項」もあわせてご覧ください。</li>
              </ul>
            </td>
          </tr>
        </table>
      </div>
      <!-- /blc:メールアドレス登録／変更 -->

      <!-- sblc -->
      <div class="sblc">
        <div class="btn-area">
          <button class="btn btn01 bs" @click="toUcomTop()">UCOM光レジデンス会員専用トップへ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
      <!-- /sblc -->
    </div>
    <!-- /contents -->
  </div>
</template>

<style lang="scss" scoped>
.text-box {
  padding-bottom: 20px;
}

.caution-title {
  margin-bottom: 12px;
}

.margin-vertical-12 {
  margin: 12px 0;
}

.url {
  text-decoration: underline;
}
</style>

<script lang="ts">
import LoadingComponent from '@/shared/components/loading-component.vue';
import { AuthService } from '@/shared/services/auth/auth-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ucom-mail-address-register-completed',
  components: {
    LoadingComponent,
  },
  data: () => ({
    isLoading: false,
    mailAddressType: {
      UCOM: '1',
      OTHER: '2',
    },
    ucomAccountId: '',
    /** 画面表示用のデータ */
    displayData: {
      accountName: '',
      mailAddressType: '',
      displayMailAddressType: '',
      mailAddress: '',
      registerSubscriptionOn: '',
      isChange: '',
    },
    /** UCOM の URL */
    ucomUrl: process.env.VUE_APP_UCOM_UPDATE_MAIL_CAUTION_URL,
    /** UCOMのURL */
    ucomRUrl: process.env.VUE_APP_UCOM_URL,
  }),
  async mounted(): Promise<void> {
    this.isLoading = true;
    try {
      const isLoggedIn = await AuthService.isLoggedIn();
      if (!isLoggedIn) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }
    } catch (error) {
      // エラー画面に遷移
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/error').catch((error: any) => {
        checkRouterError(error);
      });
      return;
    }

    // Storeから情報を取得する
    this.$data.ucomAccountId = this.$store.getters['ucomAccountOptionStore/ucomAccountId'];
    this.$data.displayData.accountName = this.$store.getters['ucomAccountOptionStore/accountName'];
    this.$data.displayData.displayMailAddressType = this.$store.getters['ucomAccountOptionStore/displayMailAddressType'];
    this.$data.displayData.mailAddress = this.$store.getters['ucomAccountOptionStore/mailAddress'];
    this.$data.displayData.mailAddressType = this.$store.getters['ucomAccountOptionStore/mailAddressType'];
    this.$data.displayData.isChange = this.$store.getters['ucomAccountOptionStore/isChange'];
    this.$data.displayData.registerSubscriptionOn = this.$store.getters['ucomAccountOptionStore/registerSubscriptionOn'];

    this.isLoading = false;
  },
  methods: {
    async toUcomTop() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
  },
});
</script>
