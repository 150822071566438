<template>
  <div>
    <LoadingComponent v-if="isLoading" />
    <!-- underlayer-main -->
    <main class="underlayer-main">
      <h1>アカウントID情報一覧</h1>
    </main>
    <!-- /underlayer-main -->

    <!-- contents -->
    <div class="contents">
      <!-- breadcrumb -->
      <ul class="breadcrumb">
        <li><router-link to="/platform">トップページ</router-link></li>
        <li>
          <router-link to="/ucom">UCOM光レジデンス会員専用トップ</router-link>
        </li>
        <li>
          <router-link to="/ucom/account-option-change/account-list">アカウントID情報一覧</router-link>
        </li>
      </ul>
      <!-- /breadcrumb -->

      <!-- blc:アカウントID登録・変更-->
      <div class="blc">
        <h2 class="portal-h2 cf"><img src="../../../images/service-h2.svg" alt="アカウントID情報一覧" />アカウントID情報一覧</h2>
        <!--TODO アカウントIDのリスト表示 + トグル関係 -->
        <div v-for="info in accountInfoList" :key="info.ucomAccountId">
          <h3 v-if="!info.isSelected" v-on:click="onAccountId(info.accountName)" class="convert-to-inline">
            <font v-if="accountEmpty" color="red">▶&emsp;</font><u style="cursor: pointer">{{ info.accountName }}</u>
          </h3>
          <h3 v-if="info.isSelected"><font color="red" class="convert-to-inline">▼&emsp;</font>{{ info.accountName }}</h3>
        </div>
      </div>

      <!-- blc:アカウントID情報 -->
      <div v-for="info in accountInfoList" :key="info.ucomAccountId" class="sblc">
        <div v-if="info.isSelected" class="sblc">
          <div class="sblc">
            <div>
              <div class="accountIdTitle">
                <h3 class="service-h3">アカウントID基本情報</h3>
                <span href="" style="text-align: right"><u class="link-color" @click.prevent v-on:click="onAccountInfoChange(info)">変更</u></span>
              </div>
              <table class="table-type2">
                <tr>
                  <th class="va-middle">氏名</th>
                  <td>（姓）{{ info.familyName }}&emsp;（名）{{ info.firstName }}</td>
                </tr>
                <tr>
                  <th class="va-middle">氏名カナ</th>
                  <td>（姓カナ）{{ info.familyNameKana }}&emsp;（名カナ）{{ info.firstNameKana }}</td>
                </tr>
                <tr>
                  <th class="va-middle">ユーザネーム</th>
                  <td>{{ info.userName }}</td>
                </tr>
                <tr>
                  <th class="va-middle">アカウントパスワード</th>
                  <td>{{ info.password }}</td>
                </tr>
                <tr>
                  <th class="va-middle">アカウント権限種別</th>
                  <td>{{ info.accountAuthorization }}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="sblc">
            <div>
              <h3 class="service-h3">メールサービス</h3>
              <table v-if="!info.mailAddress" class="table-type2">
                <tr>
                  <th class="va-middle">メールアドレス</th>
                  <td>
                    未登録<span @click.prevent v-on:click="onMailAddressChange(info)" style="float: right" href=""><u class="link-color">登録</u></span>
                  </td>
                </tr>
              </table>
              <table v-if="info.mailAddress" class="table-type2">
                <tr>
                  <th v-if="info.mailAddressType === '2'" class="va-middle">他社メールアドレス</th>
                  <th v-else class="va-middle">メールアドレス</th>
                  <td>
                    {{ info.mailAddress }}<span @click.prevent v-on:click="onMailAddressChange(info)" style="float: right" href=""><u class="link-color">変更</u></span>
                  </td>
                </tr>
                <tr v-if="info.isUcomMail">
                  <th class="va-middle">POPサーバー</th>
                  <td>
                    <div v-if="info.isOldDomain == UCOM_IS_OLD_DOMAIN.NEW_DOMAIN">{{ info.popServer }}</div>

                    <span v-if="info.isOldDomain == UCOM_IS_OLD_DOMAIN.OLD_DOMAIN" @click.prevent v-on:click="onServerConfirm(info)" href="" style="float: right"
                      ><u class="link-color">確認はこちら</u></span
                    >
                  </td>
                </tr>
                <tr v-if="info.isUcomMail">
                  <th class="va-middle">SMTPサーバー</th>
                  <td>
                    <div v-if="info.isOldDomain == UCOM_IS_OLD_DOMAIN.NEW_DOMAIN">{{ info.smtpServer }}</div>

                    <span v-if="info.isOldDomain == UCOM_IS_OLD_DOMAIN.OLD_DOMAIN" @click.prevent v-on:click="onServerConfirm(info)" href="" style="float: right"
                      ><u class="link-color">確認はこちら</u></span
                    >
                  </td>
                </tr>
                <tr v-if="info.isUcomMail">
                  <th class="va-middle">IMAPサーバー</th>
                  <td>
                    <div v-if="info.isOldDomain == UCOM_IS_OLD_DOMAIN.NEW_DOMAIN">{{ info.imapServer }}</div>

                    <span v-if="info.isOldDomain == UCOM_IS_OLD_DOMAIN.OLD_DOMAIN" @click.prevent v-on:click="onServerConfirm(info)" href="" style="float: right"
                      ><u class="link-color">確認はこちら</u></span
                    >
                  </td>
                </tr>
                <tr v-if="info.isUcomMail">
                  <th class="va-middle">メールボックス容量</th>
                  <td>
                    <span v-if="info.isOldDomain == UCOM_IS_OLD_DOMAIN.NEW_DOMAIN"
                      >合計{{ info.mailSumCappacity }}GB（基本{{ info.mailboxDefaultCapacity }}GB+{{ info.mailboxAddCapacity }}GB）</span
                    >
                    <span @click.prevent v-on:click="onMailboxCapacityChange(info)" v-if="isAdminLoggedIn" style="float: right" href="">
                      <u class="link-color" v-if="info.isOldDomain == UCOM_IS_OLD_DOMAIN.NEW_DOMAIN">変更</u><u class="link-color" v-else>{{ info.linkText }}</u>
                    </span>
                    <span v-else @click.prevent v-on:click="onServerConfirm(info)" style="float: right" href="">
                      <u class="link-color" v-if="info.isOldDomain == UCOM_IS_OLD_DOMAIN.OLD_DOMAIN">確認はこちら</u>
                    </span>
                  </td>
                </tr>
                <tr v-if="info.isUcomMail">
                  <th class="va-middle">自動転送設定</th>
                  <td>
                    <span>{{ info.mailForwardSettingValue }}</span>
                    <a :href="info.transmissionUrl" style="float: right" target="_blank">
                      <u class="link-color">{{ info.linkText }}</u></a
                    >
                  </td>
                </tr>
                <tr v-if="info.isUcomMail">
                  <th class="va-middle">自動返信設定</th>
                  <td>
                    <span>{{ info.mailReplySettingValue }}</span>
                    <a :href="info.replyUrl" style="float: right" target="_blank">
                      <u class="link-color">{{ info.linkText }}</u></a
                    >
                  </td>
                </tr>
                <tr v-if="info.isUcomMail">
                  <th class="va-middle">ウイルスチェックサービス</th>
                  <td>
                    <div v-if="info.isOldDomain == UCOM_IS_OLD_DOMAIN.NEW_DOMAIN"><span v-if="info.virusCheckId">購入済み</span><span v-else>未購入</span></div>
                    <span @click.prevent v-on:click="onVirusCheckChange(info)" style="float: right" href=""
                      ><u class="link-color" v-if="info.isOldDomain == UCOM_IS_OLD_DOMAIN.NEW_DOMAIN">変更</u><u class="link-color" v-else>{{ info.linkText }}</u></span
                    >
                  </td>
                </tr>
              </table>
              <p v-if="info.mailAddress" style="text-align: right">
                メールソフトの設定は<a :href="`${ucomUrl}/option/mail/`" target="_blank"><u class="link-color">「こちら」</u></a>
              </p>
            </div>
          </div>
          <div class="sblc">
            <div v-if="info.isUcomMail">
              <h3 class="service-h3">Webスペースサービス</h3>
              <table v-if="!info.homepageAddress" class="table-type2">
                <tr>
                  <th class="va-middle">ホームページアドレス</th>
                  <td>
                    未登録
                    <span v-if="isAdminLoggedIn && info.mailAddress" @click.prevent v-on:click="onHomepageAddressInput(info)" style="float: right" href="">
                      <u class="link-color">登録</u>
                    </span>
                  </td>
                </tr>
              </table>
              <table v-if="info.homepageAddress" class="table-type2">
                <tr>
                  <th class="va-middle">ホームページアドレス</th>
                  <td>{{ info.homepageAddress }}</td>
                </tr>
                <tr>
                  <th class="va-middle">FTPサーバー</th>
                  <td>
                    <div>{{ info.ftpServer }}</div>
                  </td>
                </tr>
                <tr>
                  <th class="va-middle">Webスペース容量</th>
                  <td>
                    <div>合計{{ info.webSpaceSumCapacity }}MB（基本{{ info.webSpaceDefaultCapacity }}MB+{{ info.webspaceAddCapacity }}MB）</div>
                    <span @click.prevent v-on:click="onWebCapacityChange(info)" style="float: right" href="">
                      <u class="link-color">変更</u>
                    </span>
                  </td>
                </tr>
              </table>
              <p v-if="info.homepageAddress" style="text-align: right">
                FTPソフトの設定は<a href="https://help.ucom.ne.jp/type001/service/webspace/ftp/index.html" target="_blank"><u class="link-color">「こちら」</u></a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- /blc:アカウントID情報 -->

      <!-- blc -->
      <div class="blc">
        <div class="btn-area">
          <button class="btn btn01 bs" v-on:click="goTop()">UCOM光レジデンス会員専用トップ<i class="material-icons link link-icon">east</i></button>
        </div>
      </div>
      <!-- /blc -->
    </div>
  </div>
  <!-- /contents -->

  <!-- /main-contents -->
</template>

<style lang="scss" scoped>
.convert-to-inline {
  display: inline;
}
.accountIdTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.link-color {
  color: #cf1225;
  text-decoration: underline;
  text-decoration-color: #cf1225;
  cursor: pointer;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { Member } from '@/shared/classes/spf-api/member';
import { SpfApiAccountOptionChangeAccessor } from '@/infra/accessor/spf/ucom/spf-api-account-option-change-accessor';
import { UCOM_ACCOUNT_OPTION_QUERY_TYPE } from '@/shared/const/ucom/ucom-account-option';
import { UCOM_IS_OLD_DOMAIN } from '@/shared/const/ucom/ucom-is-old-domain';
import { UCOM_ACCOUNT_AUTHORITY } from '@/shared/const/ucom/ucom-account-authority';
import { UCOM_MAIL_ADDRESS_TYPE } from '@/shared/const/ucom/ucom-mail-address-type';
import { checkRouterError } from '@/shared/util/router-navigation-func';

class MyAccountInfo {
  public accountName = '';
  public familyName = '';
  public firstName = '';
  public familyNameKana = '';
  public firstNameKana = '';
  public userName = '';
  public password = '';
  public accountAuthorization = '';
  public mailAddress = '';
  public isOldDomain = '';
  public popServer = '';
  public smtpServer = '';
  public imapServer = '';
  public mailboxDefaultCapacity = '';
  public mailboxAddCapacity = '';
  public mailSumCappacity = '';
  public virusCheckId = '';
  public mailAddressType = '';
  public ucomAccountMailId = '';
  public ucomWebSpaceId = '';
  public homepageAddress = '';
  public ftpServer = '';
  public webSpaceDefaultCapacity = '';
  public webspaceAddCapacity = '';
  public webSpaceSumCapacity = '';
  public ucomAccountId = '';
  public mailForwardStatus = '';
  public mailReplyStatus = '';
  public linkText = '';
  public mailForwardSettingValue = '';
  public transmissionUrl = '';
  public mailReplySettingValue = '';
  public replyUrl = '';
  public isUcomMail = false;
  public isSelected = false;

  constructor(partial: Partial<MyAccountInfo>) {
    Object.assign(this, partial);
  }
}

export default defineComponent({
  name: 'account-info-list',
  components: {
    LoadingComponent,
  },

  data: () => ({
    isLoading: true,
    accountInfoList: new Array<MyAccountInfo>(),
    isAdminLoggedIn: false,
    accountEmpty: false,
    UCOM_IS_OLD_DOMAIN: UCOM_IS_OLD_DOMAIN,
    UNREGISTERED: '未登録',
    authorityName: {
      USER: 'ユーザー権限',
      ADMIN: '管理者権限',
      CUSTOMER_NUMBER: 'お客様番号権限',
    },
    /** UCOM の URL */
    ucomUrl: process.env.VUE_APP_UCOM_URL,
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      // Storeから会員情報とUCOMアカウントIDを取得
      const member: Member = this.$store.getters['memberStore/member'];
      const ucomAccountId = this.$store.getters['ucomAccountStore/ucomAccount'].accountInfo.ucomAccountId;

      //APIからアカウント情報一覧を取得
      if (!member.primaryKeyUcom) {
        await this.$router.push('/error').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      const response = await SpfApiAccountOptionChangeAccessor.getAccountInfoList(member.id, Number(ucomAccountId));

      this.accountInfoList = response.accountInfoList.map((value) => {
        return new MyAccountInfo({
          accountName: value.accountName,
          familyName: value.familyName,
          firstName: value.firstName,
          familyNameKana: value.familyNameKana,
          firstNameKana: value.firstNameKana,
          userName: value.userName,
          password: value.password,
          accountAuthorization: value.accountAuthorization,
          mailAddress: value.mailAddress,
          isOldDomain: value.isOldDomain,
          popServer: value.popServer,
          smtpServer: value.smtpServer,
          imapServer: value.imapServer,
          mailboxDefaultCapacity: value.mailboxDefaultCapacity,
          mailboxAddCapacity: value.mailboxAddCapacity,
          mailSumCappacity: value.mailSumCappacity,
          virusCheckId: value.virusCheckId,
          mailAddressType: value.mailAddressType,
          ucomAccountMailId: value.ucomAccountMailId,
          ucomWebSpaceId: value.ucomWebSpaceId,
          homepageAddress: value.homepageAddress,
          ftpServer: value.ftpServer,
          webSpaceDefaultCapacity: value.webSpaceDefaultCapacity,
          webspaceAddCapacity: value.webspaceAddCapacity,
          webSpaceSumCapacity: value.webSpaceSumCapacity,
          ucomAccountId: value.ucomAccountId,
          mailForwardStatus: value.mailForwardStatus,
          mailReplyStatus: value.mailReplyStatus,
          linkText: value.linkText,
          mailForwardSettingValue: value.mailForwardSettingValue,
          transmissionUrl: value.transmissionUrl,
          mailReplySettingValue: value.mailReplySettingValue,
          replyUrl: value.replyUrl,
        });
      });

      // 選択フラグとUCOMメールかどうかの情報をListに追加する
      for (let i = 0; i < this.accountInfoList.length; i++) {
        this.accountInfoList[i].isSelected = false;
        if (i == 0) {
          this.accountInfoList[i].isSelected = true;
        }
        if (this.accountInfoList[i].mailAddressType == UCOM_MAIL_ADDRESS_TYPE.UCOM_HIKARI.toString()) {
          this.accountInfoList[i].isUcomMail = true;
        }
      }

      this.isAdminLoggedIn = this.getIsAdminLoggedIn();

      this.accountEmpty = this.accountInfoList.length === 0 ? false : true;

      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/error').catch((error) => {
        checkRouterError(error);
      });
      return;
    }
  },
  methods: {
    async goTop() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    /** アカウントIDクリック */
    async onAccountId(accountName: string) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      for (let i = 0; i < this.accountInfoList.length; i++) {
        this.accountInfoList[i].isSelected = false;
        if (this.accountInfoList[i].accountName == accountName) {
          this.accountInfoList[i].isSelected = true;
        }
      }

      this.isLoading = false;
    },
    async onAccountInfoChange(accountInfo: MyAccountInfo) {
      this.isLoading = true;
      /** 入力中判定となるステートをストアから削除する */
      this.$store.commit('ucomAccountManagementInputStore/accountName', undefined);
      //押下された行のアカウントIDをstoreに保存する
      this.$store.commit('ucomAccountManagementStore/ucomAccountId', accountInfo.ucomAccountId);
      //前画面の情報をstoreに保存する
      this.$store.commit('ucomAccountManagementInputStore/before', '/ucom/account-option-change/account-list');
      /** アカウントID管理：登録・変更 入力画面に遷移 */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom/account-management/input').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    /**メールアドレス登録変更リンククリック */
    async onMailAddressChange(accountInfo: MyAccountInfo) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      //storeに保存
      this.$store.commit('ucomAccountOptionStore/ucomAccountId', accountInfo.ucomAccountId);
      this.$store.commit('ucomAccountOptionStore/currentMailAddress', accountInfo.mailAddress ? accountInfo.mailAddress : this.UNREGISTERED);
      this.$store.commit('ucomAccountOptionStore/mailAddress', accountInfo.mailAddress ? accountInfo.mailAddress : null);
      this.$store.commit('ucomAccountOptionStore/mailAddressType', accountInfo.mailAddressType);
      this.$store.commit('ucomAccountOptionStore/isChange', accountInfo.mailAddress ? UCOM_ACCOUNT_OPTION_QUERY_TYPE.UPDATE : UCOM_ACCOUNT_OPTION_QUERY_TYPE.INSERT);
      this.$store.commit('ucomAccountOptionStore/accountName', accountInfo.accountName);

      //アカウントIDオプション変更：メールアドレス登録 入力画面に遷移
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom/account-option-change/mail-input').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    /**ウイルスチェック登録変更リンククリック */
    async onVirusCheckChange(accountInfo: MyAccountInfo) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      //旧ドメインの場合は現行マイページのVC画面に遷移
      if (accountInfo.isOldDomain == UCOM_IS_OLD_DOMAIN.OLD_DOMAIN) {
        window.open(process.env.VUE_APP_UCOM_MY_VIRUS_CHECK_URL + accountInfo.accountName);
        this.isLoading = false;
        return;
      }

      //storeに保存
      const member: Member = this.$store.getters['memberStore/member'];
      this.$store.commit('ucomAccountOptionStore/memberId', member.id);
      this.$store.commit('ucomAccountOptionStore/ucomAccountId', accountInfo.ucomAccountId);

      //ウイルスチェック：入力画面に遷移
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom/virus-check/input').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    /**ホームページアドレス登録リンククリック */
    async onHomepageAddressInput(accountInfo: MyAccountInfo) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      //storeに保存
      this.$store.commit('ucomAccountOptionStore/ucomAccountId', accountInfo.ucomAccountId);
      this.$store.commit('ucomAccountOptionStore/mailAddress', accountInfo.mailAddress);
      this.$store.commit('ucomAccountOptionStore/accountName', accountInfo.accountName);

      //アカウントIDオプション変更：Webスペース登録 確認画面に遷移
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom/account-option-change/web-space/confirm').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    /**Webスペース容量変更リンククリック */
    async onWebCapacityChange(accountInfo: MyAccountInfo) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      //storeに保存
      this.$store.commit('ucomAccountOptionStore/ucomAccountId', accountInfo.ucomAccountId);
      this.$store.commit('ucomAccountOptionStore/ucomAccountMailId', accountInfo.ucomAccountMailId);
      this.$store.commit('ucomAccountOptionStore/mailAddress', accountInfo.mailAddress);
      this.$store.commit('ucomAccountOptionStore/accountName', accountInfo.accountName);
      // 入力画面で入力中であることの判定に使われているステートをストアから削除する
      this.$store.commit('ucomWebSpaceStore/paymentStatus', undefined);

      // Webスペース容量変更：入力画面に遷移
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom/web-space/input').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    /**メールボックス容量変更リンククリック */
    async onMailboxCapacityChange(accountInfo: MyAccountInfo) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      //旧ドメインの場合は現行マイページのメールボックス容量画面に遷移
      if (accountInfo.isOldDomain == UCOM_IS_OLD_DOMAIN.OLD_DOMAIN) {
        window.open(process.env.VUE_APP_UCOM_MY_MAILBOX_CAPACITY_URL + accountInfo.accountName);
        this.isLoading = false;
        return;
      }

      //storeに保存
      this.$store.commit('ucomAccountOptionStore/ucomAccountId', accountInfo.ucomAccountId);
      this.$store.commit('ucomAccountOptionStore/ucomAccountMailId', accountInfo.ucomAccountMailId);
      this.$store.commit('ucomAccountOptionStore/mailAddressType', accountInfo.mailAddressType);

      // メールボックス容量変更：入力画面に遷移
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await this.$router.push('/ucom/mailbox-capacity/input').catch((error: any) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    onServerConfirm(accountInfo: MyAccountInfo) {
      if (accountInfo.isOldDomain == UCOM_IS_OLD_DOMAIN.OLD_DOMAIN) {
        window.open(process.env.VUE_APP_UCOM_CONTRACT_ACCOUNT_INFO_URL);
        this.isLoading = false;
        return;
      }
    },
    /**
     * ログインユーザが管理者権限を持つかどうかを返す
     */
    getIsAdminLoggedIn(): boolean {
      const loginUserAuthority: UCOM_ACCOUNT_AUTHORITY = this.$store.getters['ucomAccountStore/ucomAccount'].accountInfo.accountAuthorization;
      if (loginUserAuthority === UCOM_ACCOUNT_AUTHORITY.ADMIN_ACCOUNT) {
        return true;
      }
      return loginUserAuthority === UCOM_ACCOUNT_AUTHORITY.CUSTOMER_NO_USER;
    },
  },
});
</script>
