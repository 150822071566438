<template>
  <div class="ucom-index">
    <main class="underlayer-main">
      <h1>会員専用トップページ</h1>
    </main>

    <!-- ISP(ucom)会員ステータスが「退会ログイン可能」のとき表示するご請求明細・販売証明書 -->
    <template v-if="isDisplayOnlyBillingDetails">
      <div class="contents">
        <ul class="breadcrumb">
          <li><router-link to="/platform">トップページ</router-link></li>
          <li>UCOM光 レジデンス 会員専用トップ</li>
        </ul>

        <div class="billing-details-container">
          <p>ご請求明細はこちらからご確認ください</p>
          <p class="description"><a :href="`${billingDetailsURL}`" class="link" target="UCOM">月額請求明細照会</a></p>
          <p class="description"><a :href="`${gateCall050BillingDetailsURL}`" class="link" target="UCOM">GATE CALL050通話明細照会</a></p>
          <p class="description"><a :href="`${ipPhoneBillingDetailsURL}`" class="link" target="UCOM">UCOM光電話通話明細照会</a></p>
          <p class="description">※ UCOM光 レジデンス退会後、13か月間はPortasにログインし、ご請求明細をご確認いただけます。</p>
        </div>

        <div class="blc">
          <p>UCOM光 レジデンスインターネットサービスについて、サービス内容やご利用方法、お問い合わせはお住まいの建物専用サイトをご覧ください。</p>
          <p class="ttl"><a :href="`${ucomUrl}/${apid}`" class="link" target="UCOM">お住まいの「建物専用サイト」はこちら</a></p>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="contents">
        <!-- breadcrumb -->
        <ul class="breadcrumb">
          <li><router-link to="/platform">トップページ</router-link></li>
          <li>UCOM光 レジデンス 会員専用トップ</li>
        </ul>
        <!-- /breadcrumb -->

        <!-- blc:UCOM光からのお知らせ -->
        <div class="blc" v-if="emInfoMessages.length">
          <h2 class="portal-h2 cf">
            <img src="../../images/service-h2.svg" alt="UCOM光からのお知らせ" />UCOM光 レジデンスからのお知らせ
            <a href="https://help.ucom.ne.jp/trouble/index.html" target="UCOM" class="link">広域での障害はこちら<i class="material-icons link link-icon">arrow_right_alt</i></a>
          </h2>
          <div class="service-news border-grey">
            <ul>
              <li class="list-none" v-for="(message, index) in emInfoMessages" :key="index">
                <span class="noticelist">{{ getKindString(message.message_kind) }}</span>
                <b>{{ message.message_date }} </b>
                <span v-if="message.message_link">
                  <a :href="message.message_link" target="UCOM" v-html="message.message_label"></a>
                </span>
                <span v-if="!message.message_link" v-html="message.message_label"></span>
              </li>
            </ul>
          </div>
        </div>
        <!-- お知らせがない場合、広域での障害のみ表示 -->
        <div class="blc" v-else>
          <h2>
            <a href="https://help.ucom.ne.jp/trouble/index.html" target="UCOM" class="link">広域での障害はこちら<i class="material-icons link link-icon">arrow_right_alt</i></a>
          </h2>
        </div>
        <!-- /blc -->

        <!-- blc:UCOM光 レジデンス 会員オプションサービス -->
        <!-- Connectix提供可能物件かつConnectix未契約の場合 -->
        <div class="blc" v-if="customer && property && isCustomerNumberOrAdmin">
          <div v-if="isApplyConnectix && !isContractConnectixFlag">
            <h2 class="portal-h2 cf"><img src="../../images/service-h2.svg" alt="インターネット通信速度にお困りの方へ" />インターネット通信速度にお困りの方へ</h2>
            <ul class="service-col3 grid pc-grid3 sp-grid1 pc-gap30 sp-gap10">
              <li class="border-grey">
                <a href="https://speedtest.tsunagunet.com/u/" target="UCOM">
                  <img src="../../images/ico01.png" alt="スピードテスト" class="mt20" />
                </a>
                <div class="inner">
                  <p class="ttl">自宅の通信環境をチェック</p>
                  <p>現在のインターネット回線の通信速度を自社グループネットワーク内の速度測定サーバでワンクリックで簡単計測‼　</p>
                  <p class="txt-right">
                    <a href="https://speedtest.tsunagunet.com/u/" target="UCOM" class="link"> スピードテストはこちら<i class="material-icons link-icon">arrow_right_alt</i> </a>
                  </p>
                </div>
              </li>
              <li class="border-grey">
                <a href="https://www.tsunagunet.com/internet/kaizen.html" target="UCOM">
                  <img src="../../images/ico02.png" alt="通信の改善" class="mt20" />
                </a>
                <div class="inner">
                  <p class="ttl">接続速度が遅いと感じた場合</p>
                  <p>
                    モジュラージャックから接続しているLANケーブルやWi-Fiルーターなどの接続機器や、パソコンなど端末に原因がある場合があります。まずはお使いの機器を確認してみましょう！
                  </p>
                  <p class="txt-right">
                    <a href="https://www.tsunagunet.com/internet/kaizen.html" target="UCOM" class="link">
                      改善方法はこちら<i class="material-icons link-icon">arrow_right_alt</i>
                    </a>
                  </p>
                </div>
              </li>
              <li class="border-grey">
                <a :href="connectixLpUrl" target="UCOM">
                  <img src="../../images/ico03.png" alt="オプション" class="mt20" />
                </a>
                <div class="inner">
                  <p class="ttl">オンライン会議や動画などもっと快適なインターネット環境を試したい方は…</p>
                  <p>インターネットオプションサービス「Connectix」お申し込みのお客様専用の優先ルートで快適インターネット</p>
                  <p class="txt-right">
                    <a :href="connectixLpUrl" target="UCOM" class="link">詳しくはこちら<i class="material-icons link-icon">arrow_right_alt</i></a>
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <!-- Connectix提供不可物件の場合 -->
          <div v-else-if="!isApplyConnectix">
            <h2 class="portal-h2 cf"><img src="../../images/service-h2.svg" alt="インターネット通信速度にお困りの方へ" />インターネット通信速度にお困りの方へ</h2>
            <ul class="service-col3 grid pc-grid3 sp-grid1 pc-gap30 sp-gap10">
              <li class="border-grey">
                <a href="https://speedtest.tsunagunet.com/ucom/" target="UCOM">
                  <img src="../../images/ico01.png" alt="スピードテスト" class="mt20" />
                </a>
                <div class="inner">
                  <p class="ttl">自宅の通信環境をチェック</p>
                  <p>現在のインターネット回線の通信速度を自社グループネットワーク内の速度測定サーバでワンクリックで簡単計測‼　</p>
                  <p class="txt-right">
                    <a href="https://speedtest.tsunagunet.com/ucom/" target="UCOM" class="link"> スピードテストはこちら<i class="material-icons link-icon">arrow_right_alt</i> </a>
                  </p>
                </div>
              </li>
              <li class="border-grey">
                <a href="https://www.tsunagunet.com/internet/kaizen_u.html" target="UCOM">
                  <img src="../../images/ico02.png" alt="通信の改善" class="mt20" />
                </a>
                <div class="inner">
                  <p class="ttl">接続速度が遅いと感じた場合</p>
                  <p>
                    モジュラージャックから接続しているLANケーブルやWi-Fiルーターなどの接続機器や、パソコンなど端末に原因がある場合があります。まずはお使いの機器を確認してみましょう！
                  </p>
                  <p class="txt-right">
                    <a href="https://www.tsunagunet.com/internet/kaizen_u.html" target="UCOM" class="link">
                      改善方法はこちら<i class="material-icons link-icon">arrow_right_alt</i>
                    </a>
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <!-- blc:各種登録情報の確認・変更 -->
          <div class="blc">
            <h2 class="portal-h2 cf"><img src="../../images/service-h2.svg" alt="UCOM光 レジデンス 各種登録情報の確認・変更" />UCOM光 レジデンス 各種登録情報の確認・変更</h2>
            <div class="border-grey">
              <ul>
                <PermissionControledListLink
                  title="ご契約情報一覧"
                  :url="`${ucomMyUrl}/member/ContractAccountInfo.aspx`"
                  :functionId="functionIdContractAccountInfo"
                  tabTarget="UCOM"
                  key="list-link-contract-info"
                />
                <PermissionControledListLink
                  title="お客様情報変更"
                  :url="`${ucomMyUrl}/member/ChangeCustomerInfo.aspx`"
                  :functionId="functionIdChangeCustomerInfo"
                  tabTarget="UCOM"
                  key="list-link-change-customer-info"
                />
                <PermissionControledListLink
                  title="お客様番号パスワード変更"
                  :url="`${ucomMyUrl}/member/ChangeCustomerPassword.aspx`"
                  :functionId="functionIdChangeCustomerPassword"
                  tabTarget="UCOM"
                  key="list-link-change-password"
                />
                <PermissionControledListLink
                  title="お支払い方法変更"
                  :url="`${ucomMyUrl}/member/ChangeCardInfo.aspx`"
                  :functionId="functionIdChangeCardInfo"
                  tabTarget="UCOM"
                  key="list-link-change-card"
                />
                <PermissionControledListLink
                  title="月額請求明細照合"
                  :url="`${billingDetailsURL}`"
                  :functionId="functionIdMonthlyBillInquiry"
                  tabTarget="UCOM"
                  key="list-link-bill"
                />
                <PermissionControledListLink
                  title="GATE CALL050通話明細照会"
                  :url="`${gateCall050BillingDetailsURL}`"
                  :functionId="functionIdMonthlyBillInquiry"
                  tabTarget="UCOM"
                  key="list-link-bill"
                />
                <PermissionControledListLink
                  title="UCOM光電話通話明細照会"
                  :url="`${ipPhoneBillingDetailsURL}`"
                  :functionId="functionIdMonthlyBillInquiry"
                  tabTarget="UCOM"
                  key="list-link-bill"
                />
              </ul>
            </div>
          </div>
          <!-- /blc -->

          <!-- blc:オプションサービスのお知らせ -->
          <PermissionControledSpanLink
            sentence="UCOM光 レジデンス 各種オプションサービスのお申し込み・変更は"
            linkTitle="こちらから"
            alt="オプションサービスのお申し込み・変更"
            tabTarget="UCOM"
            :url="`${ucomMyUrl}/member/ContractAccountInfo.aspx`"
            :functionId="functionIdOptionServices"
            key="router-link-option-service"
          />
          <!-- /blc -->

          <template v-if="isApplyConnectix">
            <div class="blc">
              <h2 class="portal-h2 cf"><img src="../../images/service-h2.svg" alt="Connectix お申し込み・解約" />Connectix お申し込み・解約</h2>
              <div class="table-scroll js-scrollable">
                <table class="table">
                  <tr>
                    <th colspan="2">サービス</th>
                    <th>料金</th>
                    <th></th>
                  </tr>
                  <tr v-if="isApplyConnectix">
                    <td colspan="2">
                      <p>Connectix</p>
                    </td>
                    <td>
                      {{ customer.connectix_price }}
                    </td>
                    <td colspan="2">
                      <!-- Connectix契約/未契約でリンクを出し分ける -->
                      <!-- 契約済み -->
                      <div v-if="customer.connectix_status" class="service-btn grid">
                        <a :href="`${ucomConnectixUrl}`" target="UCOM">ご解約</a>
                      </div>
                      <!-- 未契約 -->
                      <div v-else class="service-btn grid">
                        <router-link to="/connectix/ucom/terms" target="UCOM">サービス詳細・お申し込み</router-link>
                      </div>
                    </td>
                  </tr>
                </table>
                <p class="att grey">
                  ※価格は（税抜）の記載がないものは全て、新税率に基づく税込表示（消費税{{ taxRate }}%）です。今後消費税が改正された場合は、改正後の税率による価格に変更となります。
                </p>
              </div>
            </div>
          </template>
        </div>
        <!-- /blc -->

        <!-- blc:機器レンタルサービス -->
        <!-- コンテナ全体を権限制御 -->
        <div class="blc" v-if="isCustomerNumberOrAdmin && customer && property">
          <!-- 「機器レンタルサービス」を、契約基本情報取得APIのレスポンスにより表示制御する -->
          <div v-if="isDisplayRentalService">
            <h2 class="portal-h2 cf"><img src="../../images/service-h2.svg" alt="機器レンタルサービス" />機器レンタルサービス</h2>
            <PermissionControledRouterLink
              class="service-item-link"
              to="/ucom/rental-service/input"
              title="お申し込み"
              :functionId="functionIdAccountManagement"
              description=""
              key="router-link-rental-service-input"
              v-if="isRentalServiceApplyLink"
            />
            <p v-if="!isRentalServiceApplyLink">お申し込み済</p>
          </div>
        </div>
        <!-- /blc -->

        <!-- blc:メール・Webスペースサービス -->
        <div class="blc">
          <h2 class="portal-h2-exclusion cf"><img src="../../images/service-h2.svg" alt="メール・Webスペースサービス" /><span>メール・Webスペースサービス</span></h2>
          <p v-if="isLinkDisabled" class="red">
            ご登録いただいた移転予定日前のため、ご利用いただけません。<br />
            ※移転予定日の前日よりご利用いただけます。恐れ入りますがしばらくお待ち下さい。
          </p>
          <PermissionControledRouterLink
            class="service-item-link"
            to="/ucom/account-management/list"
            title="アカウントID管理"
            :functionId="functionIdAccountManagement"
            description="メール・Webサービスのご利用前にアカウントIDの新規登録を行ってください"
            key="router-link-account-list"
            :disabled="isLinkDisabled"
          />
          <PermissionControledRouterLink
            class="service-item-link"
            to="/ucom/account-option-change/account-list"
            title="アカウントID情報一覧"
            :functionId="functionIdAccountList"
            description="アカウントID基本情報の確認、メールアドレス変更、ウイルスチェックお申し込みなどはこちら"
            key="router-link-account-option"
            :disabled="isLinkDisabled"
          />
          <PermissionControledRouterLink
            v-if="canPurchaseMailPack"
            class="service-item-link"
            to="/ucom/mail-pack/input"
            title="メールサービス購入"
            :functionId="functionIdMailServicePurchase"
            description="メール・Webサービスのお申し込みはこちら"
            key="router-link-mail-pack"
            :disabled="isLinkDisabled"
          />
          <div class="web-mail-link service-item-link">
            <ul>
              <li>
                <template v-if="isLinkDisabled">
                  <span class="link-disabled">Webメール</span>
                </template>
                <template v-else>
                  <a :href="`${ucomUrl}/webmail/`" target="_blank">Webメール</a>
                </template>
              </li>
            </ul>
          </div>
        </div>
        <!-- /blc -->

        <!-- blc:お住まいの建物専用サイト -->
        <div class="blc">
          <p>UCOM光 レジデンスインターネットサービスについて、サービス内容やご利用方法、お問い合わせはお住まいの建物専用サイトをご覧ください。</p>
          <p class="ttl"><a :href="`${ucomUrl}/${apid}`" class="link" target="UCOM">お住まいの「建物専用サイト」はこちら</a></p>
        </div>
        <!-- /blc:お住まいの建物専用サイト -->
      </div>
    </template>

    <div class="ucom-footer">
      <!-- 物件基本情報.サービス提供元企業ID にて、画像の出し分けをする -->
      <template v-if="property && property.service_provid_former.service_provid_former_id === '1'">
        <img src="../../images/tunagunet_logo.png" class="tsunagunet" />
      </template>
      <template v-if="property && property.service_provid_former.service_provid_former_id === '2'">
        <img src="../../images/logo-arteria-networks.png" class="arteria" />
      </template>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '../../shared/libraries/scroll-hint/scroll-hint.css';

/* underlayer-main
---------------------------------------------------- */
.underlayer-main h1 {
  padding: 10px 0 10px 160px;
  display: inline;
  background-image: url('../../images/logo-ucom.png');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 140px auto;
}

/* service-top
---------------------------------------------------- */
.portal-h2-exclusion {
  font-size: 24px;
  display: flex;
}

.portal-h2-exclusion img {
  position: relative;
  top: -0.125em;
  width: auto;
  height: 1.2em;
  margin-right: 0.3em;
}

/* col3 */
.service-col3 {
  padding: 0;
  margin: 25px 0 0;
}

.service-col3 li {
  list-style: none;
}

.service-col3 li .inner {
  padding: 10px 30px 20px;
}

.service-col3 li img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.service-col3 li .ttl {
  font-size: 16px;
  font-weight: bold;
  line-height: 25px;
  margin-bottom: 10px;
}

/* service-list
---------------------------------------------------- */
.service-list {
  margin: 0;
  padding: 10px;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}

.service-list li {
  float: left;
  margin-left: 30px;
  position: relative;
  padding-left: 15px;
}

.service-list li::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: '';
  vertical-align: middle;
  width: 6px;
  height: 6px;
  border-top: 2px solid #cf1225;
  border-right: 2px solid #cf1225;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* ucom
---------------------------------------------------- */

/* first-user */
.first-user {
  padding: 0;
  margin: 25px 0 0;
}

.first-user li {
  list-style: none;
  background-color: #fcfcfc;
  padding-top: 30px;
  text-align: center;
}

.first-user li .inner {
  padding: 10px 30px 30px;
}

.first-user li img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.first-user li .ttl {
  font-size: 16px;
  font-weight: bold;
  color: #cf1225;
  text-align: center;
  line-height: 25px;
  min-height: 50px;
  margin-bottom: 20px;
}

.first-user li .service-btn a {
  line-height: 20px;
  padding: 10px;
}

/* service-news */
.service-news {
  background-color: #fcfcfc;
  padding: 0 30px 20px;
  margin: 0;
}

.service-news ul {
  padding: 0;
}

.service-news ul li {
  padding: 15px 0 15px 20px;
  border-bottom: 1px solid #cf1225;
  position: relative;
}

.service-news ul li::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: '';
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  border: 6px solid transparent;
  border-left: 10px solid #cf1225;
}

.noticelist {
  justify-content: center;
  text-align: center;
  display: inline-block;
  min-width: 100px;
  padding: 5px;
  margin: 0 10px 0 0;
  font-size: 0.7rem;
  letter-spacing: 0.05em;
  color: #cf1225;
  background: #fdeaec;
  font-weight: 500;
}

.arrow-icon {
  position: relative;
  top: -5px;
}

/* table */
.table {
  margin: 20px 0 10px;
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid #dee2e6;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table th {
  background-color: #fbfbfb;
  text-align: left;
}

.table td .service-btn {
  text-align: right;
  float: right;
  width: 320px;
  font-size: 13px;
}

.table td .service-not-purchased {
  color: #cf1225;
}

/* txt-footer */
.txt-footer {
  text-align: center;
}

.ucom-footer {
  padding: 40px 0;
  text-align: center;
  border-top: 1px solid #e6e6e6;
  & img {
    display: block;
    margin: 0 auto;
    &.tsunagunet {
      width: 250px;
    }
    &.arteria {
      width: 176px;
    }
  }
}

.banner-pc {
  display: block;
  width: 100%;
}

.banner-sp {
  display: none;
}

.billing-details-container {
  margin-top: 24px;
  & p.description {
    text-indent: 1em;
  }
}

/* web-mail-link
---------------------------------------------------- */
.web-item-link {
  margin-left: 1em;
}

.web-mail-link {
  margin: 0;
}

.web-mail-link ul {
  padding: 0;
  list-style-type: none;
}

.web-mail-link ul li {
  padding: 0 0 0 10px;
  text-decoration: underline;
  position: relative;
}

.web-mail-link ul li a {
  color: #cf1225;
  text-decoration: underline;
}

.web-mail-link ul li::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  content: '';
  box-sizing: border-box;
  width: 4px;
  height: 4px;
  border: 3px solid transparent;
  border-left: 5px solid #cf1225;
}

.link-disabled {
  color: #bdbdbd;
  text-decoration: underline;
}

@media only screen and (max-width: 767px) {
  /* underlayer-main
  ---------------------------------------------------- */
  .underlayer-main {
    padding: 49px 20px;
  }

  .underlayer-main h1 {
    padding: 10px 0 10px 120px;
    background-size: 110px auto;
  }

  /* service-top
  ---------------------------------------------------- */

  /* col3 */
  .service-col3 li .inner {
    padding: 10px 15px 20px;
  }

  /* service-list
  ---------------------------------------------------- */
  .service-list {
    padding: 10px 10px 10px 0;
  }

  .service-list li {
    margin-left: 20px;
  }

  .service-item-link {
    margin-left: 1em;
  }

  /* ucom
  ---------------------------------------------------- */

  /* first-user */
  .first-user {
    padding: 0;
    margin: 25px 0 0;
  }

  .first-user li {
    padding-top: 20px;
  }

  .first-user li .inner {
    padding: 10px 20px 20px;
  }

  .first-user li img {
    margin: 0 auto;
  }

  .first-user li .ttl {
    min-height: inherit;
  }

  /* service-news */
  .service-news {
    padding: 0 20px 15px;
  }

  .portal-h2 a {
    font-size: 12px;
    margin-left: 0px;
    float: right;
  }

  /* table */
  .table {
    margin-top: 0;
    width: 100%;
    border-collapse: collapse;
  }

  .table td,
  .table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }

  .table th {
    background-color: #fbfbfb;
    text-align: left;
  }

  .table td .service-btn {
    text-align: right;
    float: right;
    width: 260px;
    font-size: 13px;
  }

  .table-scroll table {
    width: 780px;
  }

  .portal-h2-exclusion {
    font-size: 18px;
    display: block;
  }

  .portal-h2-exclusion img {
    top: 0.1em;
  }

  .banner-pc {
    display: none;
  }

  .banner-sp {
    display: block;
    width: 100%;
  }
}
</style>
<script lang="ts">
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { EMansionProperty, EmInfoMessage } from '@/shared/classes/external-api/e-mansion/property-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { UcomCustomerResponse } from '@/shared/classes/external-api/ucom/customer-response';
import { UcomPropertyResponse } from '@/shared/classes/external-api/ucom/property-response';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { MEMBER_STATUS } from '@/shared/const/member-status';
import { ISP_MEMBER_STATUS, UA_TYPE } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import { getIspMemberStatusUcom } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { defineComponent } from 'vue';
import { UcomSharedErrorResponse } from '../../shared/classes/external-api/ucom/shared-error-response';
import PermissionControledRouterLink from '@/shared/components/permission-controled-router-link.vue';
import PermissionControledListLink from '@/shared/components/permission-controled-list-link.vue';
import PermissionControledSpanLink from '@/shared/components/permission-controled-span-link.vue';
import PermissionControledMenuLink from '@/shared/components/permission-controled-menu-link.vue';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { SERVICE_ID } from '@/shared/const/service-ids';
import { leaveFontRedAndBrTag } from '@/shared/util/func-leave-font-red-and-br-tag';
import { processDate } from '@/shared/util/func-process-date';
import { FORMAT_DATE } from '@/shared/const/format';
import dayjs from 'dayjs';

type DataType = {
  ScrollHint: any;
  customer: UcomCustomerResponse | null;
  property: UcomPropertyResponse | null;
  emInfoMessages: Array<EmInfoMessage>;
  connectixLpUrl: string;
  ucomConnectixUrl: string;
  isApplyConnectix: boolean;
  apid: string;
  ucomUrl: string;
  ucomMyUrl: string;
  isContractConnectixFlag: boolean;
  ispMemberStatusUcom: string;
  isDisplayOnlyBillingDetails: boolean;
  billingDetailsURL: string;
  gateCall050BillingDetailsURL: string;
  ipPhoneBillingDetailsURL: string;
  canPurchaseMailPack: boolean;
  planType: SERVICE_PLAN_TYPE | undefined;
  functionIdContractAccountInfo: string;
  functionIdChangeCustomerInfo: string;
  functionIdChangeCustomerPassword: string;
  functionIdChangeCardInfo: string;
  functionIdMonthlyBillInquiry: string;
  functionIdOptionServices: string;
  functionIdConnectixApplication: string;
  functionIdAccountManagement: string;
  functionIdAccountList: string;
  functionIdMailServicePurchase: string;
  functionIdPropertyInfo: string;
  taxRate: string;
  functionIdRentalService: string;
  isDisplayRentalService: boolean;
  isRentalServiceApplyLink: boolean;
};

/** 「UCOM サービストップ」画面 */
export default defineComponent({
  name: 'ucom-index',
  components: {
    PermissionControledRouterLink,
    PermissionControledListLink,
    PermissionControledSpanLink,
    PermissionControledMenuLink,
  },
  data(): DataType {
    return {
      ScrollHint: require('../../shared/libraries/scroll-hint/scroll-hint'),
      property: null,
      emInfoMessages: [],
      customer: null,
      connectixLpUrl: process.env.VUE_APP_UCOM_LP_URL,
      ucomConnectixUrl: process.env.VUE_APP_UCOM_CONNECTIX_SERVICE_URL,
      isApplyConnectix: false,
      /** 物件APID */
      apid: '',
      /** UCOM の URL */
      ucomUrl: process.env.VUE_APP_UCOM_URL,
      /** UCOM の MY URL */
      ucomMyUrl: process.env.VUE_APP_UCOM_MY_URL,
      /** Connectix契約済み/未契約フラグ */
      isContractConnectixFlag: false,
      // ISP(ucom)会員ステータス
      ispMemberStatusUcom: '',
      // ISP(ucom)会員ステータスが「退会ログイン可能」のとき表示するご請求明細・販売証明書を表示するかどうか
      isDisplayOnlyBillingDetails: false,
      // ISP(ucom)会員ステータスが「退会ログイン可能」のとき表示するご請求明細・販売証明書表示URL
      billingDetailsURL: process.env.VUE_APP_UCOM_BILLING_DETAILS_URL,
      gateCall050BillingDetailsURL: process.env.VUE_APP_UCOM_GATE_CALL_050_BILLING_DETAILS_URL,
      ipPhoneBillingDetailsURL: process.env.VUE_APP_UCOM_IPPHONE_BILLING_DETAILS_URL,
      /** メールパック購入可否 */
      canPurchaseMailPack: false,
      /** アカウント種別 */
      planType: undefined,

      // UCOM ご契約情報一覧 の機能ID
      functionIdContractAccountInfo: SERVICE_ID.UCOM.LINK_UCOM_CONTRACT_INFO_LIST,
      // UCOM お客様情報変更 の機能ID
      functionIdChangeCustomerInfo: SERVICE_ID.UCOM.LINK_UCOM_GUEST_INFO_CHANGE,
      // UCOM お客様番号パスワード変更 の機能ID
      functionIdChangeCustomerPassword: SERVICE_ID.UCOM.LINK_UCOM_GUEST_NUMBER_PASSWORD_CHANGE,
      // UCOM お支払方法変更 の機能ID
      functionIdChangeCardInfo: SERVICE_ID.UCOM.LINK_UCOM_PAYMENT_METHODS_CHANGE,
      // UCOM 月額請求明細照合 の機能ID
      functionIdMonthlyBillInquiry: SERVICE_ID.UCOM.LINK_UCOM_MONTHLY_BILL_INQUIRY,
      // UCOM UCOM光レジデンス 各種オプションサービスのお申し込み・変更はこちらから の機能ID
      functionIdOptionServices: SERVICE_ID.UCOM.LINK_UCOM_OPTION_SERVICES,
      // UCOM Connectix お申し込み・解約 の機能ID
      functionIdConnectixApplication: SERVICE_ID.UCOM.LINK_CONNECTIX_APPLICATION_CANSEL,
      // UCOM アカウントID管理 の機能ID
      functionIdAccountManagement: SERVICE_ID.UCOM_ACCOUNT_ID_MANAGEMENT,
      // UCOM アカウントID情報一覧 の機能ID
      functionIdAccountList: SERVICE_ID.UCOM_ACCOUNT_ID_OPTION_CHANGE,
      // UCOM メールサービス購入 の機能ID
      functionIdMailServicePurchase: SERVICE_ID.UCOM_MAIL_SERVICE_PURCHASE,
      // UCOM お住まいの「建物専用サイト」はこちら の機能ID
      functionIdPropertyInfo: SERVICE_ID.UCOM.LINK_UCOM_BUILDING_ONLY_SITE,
      taxRate: '',
      // UCOM 機器レンタルサービス の機能ID
      functionIdRentalService: SERVICE_ID.UCOM_RENTAL_SERVICE,
      // 機器レンタルサービスコンテナの表示可否
      isDisplayRentalService: false,
      // UCOM 機器レンタルサービスのお申込みリンクの出し分け(リンク or テキスト)判定
      isRentalServiceApplyLink: false,
    };
  },
  emits: ['isMemberTopMountingUcom'],
  async mounted() {
    try {
      const isLoggedIn = await AuthService.isLoggedIn();
      // ログインしていない場合「総合トップ」画面にリダイレクトする
      if (!isLoggedIn) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await this.$router.push('/').catch((error: any) => {
          checkRouterError(error);
        });
        return;
      }

      /** 会員情報を取得 */
      const member: Member = this.$store.getters['memberStore/member'];
      /** 会員の物件IDに紐づく物件を取得 */
      const memberProperty = await SpfApiService.findPropertyById(member.propertyId!);
      /** 物件テーブルに UCOM物件ID および Mye物件ID が含まれていなければエラー画面 */
      if (!memberProperty.buildingId || !memberProperty.apartmentId) {
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.UCOM.NO_BUILDING_ID);
      }
      this.apid = this.toApid(memberProperty.apartmentId);

      /** 契約基本情報をStoreから取得 */
      const customer = await this.$store.dispatch('ucomCommonStore/customer', member.primaryKeyUcom);
      if (customer instanceof UcomCustomerResponse) {
        // データ設定
        this.customer = customer;
        // Connectixの判定
        this.$data.isApplyConnectix = customer.connectix_offer;
        this.$data.isContractConnectixFlag = this.customer.connectix_status;

        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusUcom = getIspMemberStatusUcom(memberStatus, customer);
        this.isDisplayOnlyBillingDetails = this.getFlagDisplayOnlyBillingDetails(memberStatus);

        // 機器レンタルサービスコンテナの表示可否の判定を評価
        const rentalBbrOffer = customer.rental_bbr_offer;
        const rentalBbrStartDate = customer.rental_bbr_start_date;
        const isDisplayRentalService = rentalBbrOffer && new Date(rentalBbrStartDate) <= new Date();
        this.$data.isDisplayRentalService = isDisplayRentalService;

        // 機器レンタルサービスお申し込みリンクの出し分け(リンク or テキスト)を判定
        this.$data.isRentalServiceApplyLink = customer.is_possible_rental_bbr_apply;
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.CONTRACTOR_INFO);
      }

      // 顧客に物件が紐付いていない場合エラーとする
      if (!member.propertyId) {
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_PROPERTY);
      }

      /** 物件基本情報をStoreから取得 */
      const propertyInfo: UcomPropertyResponse | UcomSharedErrorResponse = await this.$store.dispatch('ucomCommonStore/property', memberProperty.buildingId);
      if (propertyInfo instanceof UcomPropertyResponse) {
        // データ設定
        this.property = propertyInfo;
      } else {
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.PROPERTY_INFO);
      }

      // e-mansion 物件基本情報を Store から取得（重複利用は生じないので e-mansion の Store をそのまま使用する）
      const propertyParams = { apartmentId: memberProperty.apartmentId, uaType: UA_TYPE.UCOM };
      const eMansionProperty: EMansionProperty | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/property', propertyParams);
      if (eMansionProperty instanceof EMansionProperty) {
        if (eMansionProperty.em_info_message !== null) {
          this.emInfoMessages = eMansionProperty.em_info_message.map((message) => {
            message.message_label = leaveFontRedAndBrTag(message.message_label);
            return message;
          });
        }
      } else {
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.E_MANSION_API_PROPERTY_INFO);
      }

      // メールパック購入可否を API から取得する
      this.canPurchaseMailPack = this.$store.getters['ucomAccountStore/ucomAccount']?.isEmailPackPurchaseEnabled;

      // アカウント種別を取得
      this.planType = this.$store.getters['servicePlanTypeStore/servicePlanType'];

      // 消費税を取得
      this.$data.taxRate = this.$store.getters['taxStore/tax'];
    } catch (error) {
      throw error;
    }

    this.$nextTick(() => {
      this.jsScrollAble();
    });
    this.$emit('isMemberTopMountingUcom', false); // pages/index.vue 宛てにmounted終了を通知
  },
  methods: {
    /** APID変換 */
    toApid(apid: string): string {
      return convert6DigitIdTo8DigitId(apid);
    },
    getKindString(kind: string): string {
      let kindStr = '';
      switch (kind) {
        case '01':
          kindStr = 'お知らせ';
          break;
        case '02':
          kindStr = 'キャンペーン';
          break;
        case '03':
          kindStr = 'メンテナンス';
          break;
        case '04':
          kindStr = '障害';
          break;
        case '05':
          kindStr = '障害「マンション全体」';
          break;
        case '06':
          kindStr = '障害「マンション一部」';
          break;
        default:
          kindStr = 'お知らせ';
          break;
      }
      return kindStr;
    },
    jsScrollAble() {
      new this.ScrollHint('.js-scrollable');
      new this.ScrollHint('.js-scrollable-shadow', {
        suggestiveShadow: true,
      });
    },
    /**
     * ISP(ucom)会員ステータスが「退会ログイン可能」のとき表示するご請求明細・販売証明書を表示するかどうか
     *
     * | isDisplayOnlyBillingDetails | isp-member-status | portas-member-status |
     * | true                        | cancelAndLoginOK  | 5                    |
     * | true                        | cancelAndLoginOK  | 7                    |
     */
    getFlagDisplayOnlyBillingDetails(memberStatus: MemberStatus) {
      const isExpectedPortasMemberStatus = [MEMBER_STATUS.PROPERTY_LINKED, MEMBER_STATUS.IN_CANCEL_APPLICATION].some((value) => value === memberStatus.status);

      if (isExpectedPortasMemberStatus && this.ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_OK) {
        return true;
      }
      return false;
    },
  },
  computed: {
    /** アカウント種別が「お客様番号権限」または「管理者権限」である */
    isCustomerNumberOrAdmin(): boolean {
      return this.planType === SERVICE_PLAN_TYPE.UC_CUSTOMER_NO || this.planType === SERVICE_PLAN_TYPE.UC_ADMIN;
    },
    /**
     * リンクを非活性にするかどうか
     *
     * ページを訪れた日が 2023/07/25 とする
     *
     * 時間入れない日付で判定
     * (空文字)'' => 入居済み(活性)
     * (過去日付)'2023/07/24' => 入居済み(活性)
     * (当日日付)'2023/07/25' => 入居済み(活性)
     * (前日日付)'2023/07/26' => 未入居(活性)
     * (未来日付)'2023/07/27' => 未入居(非活性)
     *
     * ture: リンクを非活性にする
     * false: リンクを活性化する
     */
    isLinkDisabled(): boolean {
      if (this.customer) {
        // 空文字のとき: 入居済み
        if (this.customer.move_into_date === '') {
          return false;
        } else {
          const formattedToday = processDate(FORMAT_DATE.YYYYMMDD_WITH_SLASH);
          const afterOneDayFromToday = dayjs(formattedToday).add(1, 'day').format(FORMAT_DATE.YYYYMMDD_WITH_SLASH);

          // 今日の1日後が入居予定日であれば活性化させる
          if (this.customer.move_into_date === afterOneDayFromToday) {
            return false;
          } else if (this.customer.move_into_date > formattedToday) {
            return true;
          } else {
            return false;
          }
        }
      }
      return true;
    },
  },
});
</script>
