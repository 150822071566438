<template>
  <div class="top">
    <LoadingComponent v-if="!isMounted" />

    <div class="my-section-outer">
      <LoginAndRegisterGuideAtTop />
    </div>

    <div class="my-section-outer">
      <MainSwiperComponent :status="status" :passEventToParentComponent="handlePassedEventFromChildComponent" />
    </div>

    <div class="my-section-outer connectix">
      <ConnectixAtTop />
    </div>

    <div class="my-section-outer">
      <MainSwiperComponent :isCoupon="isCoupon" :passEventToParentComponent="handlePassedEventFromChildComponent" />
    </div>

    <!-- つなぐネットオンラインショップ案内表示-->
    <div class="my-section-wrapper tsunagu-online-shop">
      <div class="my-section">
        <TsunaguOnlineShop ref="tsunaguOnlineShop" />
      </div>
    </div>

    <div class="my-section-outer">
      <TopPageAnnouncements :status="status" />
    </div>

    <div id="faq-at-top" class="my-section-wrapper">
      <div class="my-section padding-top-0">
        <FrequentlyAskedQuestions :serviceType="'top'" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
div.my-section-wrapper {
  max-width: 960px;
  margin: 0 auto;
  &.tsunagu-online-shop {
    max-width: 1220px;
  }
}

div.my-section-outer {
  margin-bottom: 40px;
  &.connectix {
    margin-bottom: 64px;
  }
  &.coupon-and-campaign {
    margin-bottom: 0;
  }
}

div.my-section {
  padding: 40px 16px;
  &.padding-top-0 {
    padding-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .top {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 1250px) {
  div.my-section-wrapper.tsunagu-online-shop {
    div.my-section {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  div.my-section {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';

import ConnectixAtTop from './components/connectix-at-top.vue';

import FrequentlyAskedQuestions from './components/frequently-asked-questions.vue';
import LoginAndRegisterGuideAtTop from './components/login-and-register-guide-at-top.vue';
import TopPageAnnouncements from './components/top-page-announcements.vue';

import MainSwiperComponent from '@/pages/components/main-swiper.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import TsunaguOnlineShop from '@/shared/components/tnos/tsunagu-online-shop.vue';

export default defineComponent({
  name: 'top',
  components: {
    LoadingComponent,
    FrequentlyAskedQuestions,
    TsunaguOnlineShop,
    MainSwiperComponent,
    LoginAndRegisterGuideAtTop,
    ConnectixAtTop,
    TopPageAnnouncements,
  },
  data: () => ({
    status: 'top',
    isCoupon: 'isTopCoupon',
    isMounted: false,
  }),
  emits: ['isLoggedIn'],
  async mounted() {
    this.$nextTick(() => {
      this.isMounted = true;
    });
    this.$emit('isLoggedIn'); // /pages/index.vue宛てにログインしていないことを通知
  },
  methods: {
    /**
     * swiperの詳しく見るボタンをクリックされたとき、スクレイピング表示を実行する
     * TsunaguOnlineShop の メソッドまでイベントをリレーする
     */
    handlePassedEventFromChildComponent(e: Event): void {
      e.preventDefault();

      this.$nextTick(() => {
        const tsunaguOnlineShop = this.$refs.tsunaguOnlineShop as InstanceType<typeof TsunaguOnlineShop>;
        if (tsunaguOnlineShop) {
          tsunaguOnlineShop.displayTsunaguOnlineProductHtmlWrapper(e);
        }
      });
    },
  },
});
</script>
