import { TsunaguOnlineProduct } from '@/shared/classes/tnos/tsunagu-online-product';

export const TSUNAGU_ONLINE_PRODUCT_LIST = [
  {
    imgSrc: require('../../../images/tnshop/image_01.jpg'),
    category: 'インターネット通信機器',
    productName: '無線LANアクセスポイント（Wi-Fiルーター） NEC Aterm WG1200HP4',
    price: '11000',
    url: `${process.env.VUE_APP_TNOS_BASE_URL}/item/NB1Y1W03210201.html`,
    dispalyAtUnLoggedInOrUnregisteredResidence: true,
    dispalyAtUcom: true,
    dispalyAtEMansion: true,
    dispalyAtFiveA: true,
  },
  {
    imgSrc: require('../../../images/tnshop/image_02.jpg'),
    category: '訪問サポート',
    productName: '無線LANアクセスポイント【Wi-Fiルーター本体＋取付セット】（NEC Aterm WG1200HP4）',
    price: '28600',
    url: `${process.env.VUE_APP_TNOS_BASE_URL}/item/NK1D2H0J210201.html`,
    dispalyAtUnLoggedInOrUnregisteredResidence: false,
    dispalyAtUcom: false,
    dispalyAtEMansion: true,
    dispalyAtFiveA: false,
  },
  {
    imgSrc: require('../../../images/tnshop/image_03.jpg'),
    category: 'インターネット通信機器',
    productName: '無線LANアクセスポイント （Wi-Fiルーター） NEC Aterm WX1500HP',
    price: '11440',
    url: `${process.env.VUE_APP_TNOS_BASE_URL}/item/NB1Y1W032307YW.html`,
    dispalyAtUnLoggedInOrUnregisteredResidence: true,
    dispalyAtUcom: true,
    dispalyAtEMansion: true,
    dispalyAtFiveA: true,
  },
  {
    imgSrc: require('../../../images/tnshop/image_04.png'),
    category: '訪問サポート',
    productName: '無線LANアクセスポイント【Wi-Fiルーター本体＋取付セット】（NEC Aterm WX1500HP）',
    price: '29040',
    url: `${process.env.VUE_APP_TNOS_BASE_URL}/item/NK1D1H0J2307DH.html`,
    dispalyAtUnLoggedInOrUnregisteredResidence: false,
    dispalyAtUcom: false,
    dispalyAtEMansion: true,
    dispalyAtFiveA: false,
  },
  {
    imgSrc: require('../../../images/tnshop/image_06.png'),
    category: 'インターネット通信機器',
    productName: '宅内ハブ 本体 （8ポート／電源外付型／1000BASE-T対応）',
    price: '5720',
    url: `${process.env.VUE_APP_TNOS_BASE_URL}/item/NB1E1H01230601.html`,
    dispalyAtUnLoggedInOrUnregisteredResidence: false,
    dispalyAtUcom: true,
    dispalyAtEMansion: true,
    dispalyAtFiveA: false,
  },
  {
    imgSrc: require('../../../images/tnshop/image_07.jpg'),
    category: '訪問サポート',
    productName: '訪問サポート【宅内ハブ 本体＋取り付けパック】 （8ポート／電源外付型／1000BASE-T対応）【ホワイト】',
    price: '22770',
    url: `${process.env.VUE_APP_TNOS_BASE_URL}/item/NK1D2H07230601W.html`,
    dispalyAtUnLoggedInOrUnregisteredResidence: false,
    dispalyAtUcom: false,
    dispalyAtEMansion: true,
    dispalyAtFiveA: false,
  },
  {
    imgSrc: require('../../../images/tnshop/image_08.jpg'),
    category: '防災グッズ',
    productName: '書籍「マンション・地震に備えた暮らし方」',
    price: '1320',
    url: `${process.env.VUE_APP_TNOS_BASE_URL}/item/LB1T1B04150701.html`,
    dispalyAtUnLoggedInOrUnregisteredResidence: true,
    dispalyAtUcom: true,
    dispalyAtEMansion: true,
    dispalyAtFiveA: true,
  },
  {
    imgSrc: require('../../../images/tnshop/image_09.jpg'),
    category: '防災グッズ',
    productName: '災害用トイレセット　マイレット  S-100',
    price: '12683',
    url: `${process.env.VUE_APP_TNOS_BASE_URL}/item/LB1T1BO3220112.html`,
    dispalyAtUnLoggedInOrUnregisteredResidence: true,
    dispalyAtUcom: true,
    dispalyAtEMansion: true,
    dispalyAtFiveA: true,
  },
  {
    imgSrc: require('../../../images/tnshop/image_10.png'),
    category: '防災グッズ',
    productName: '永谷園バリエーションセット【※】',
    price: '10346',
    url: `${process.env.VUE_APP_TNOS_BASE_URL}/item/LB1T1B31230613.html`,
    dispalyAtUnLoggedInOrUnregisteredResidence: false,
    dispalyAtUcom: true,
    dispalyAtEMansion: true,
    dispalyAtFiveA: true,
  },
  {
    imgSrc: require('../../../images/tnshop/image_14.jpg'),
    category: 'インターネット通信機器',
    productName: 'Wi-Fi6E対応ルーター NEC Aterm WX11000T12',
    price: '54978',
    url: `${process.env.VUE_APP_TNOS_BASE_URL}/item/NB1Y1W042308YW.html`,
    dispalyAtUnLoggedInOrUnregisteredResidence: true,
    dispalyAtUcom: true,
    dispalyAtEMansion: true,
    dispalyAtFiveA: true,
  },
] as Array<TsunaguOnlineProduct>;
